* {
  font-family: Zen Kaku Gothic New;
}

.gallery img {
  transition: opacity 0.5s;
}

.gallery img:hover {
  opacity: 0.8;
}

.react-images__navigation button {
  background-color: #fff;
}

.react-images__navigation button:hover {
  background-color: #fff;
  opacity: 0.9;
}

.sticky {
  background-color: #fff;
}

.ant-result-subtitle {
  white-space: break-spaces;
}
.react-images__navigation .css-xfk84m::after {
  content: "";
  width: 22px;
  background: url("/right.svg") no-repeat center/cover;
  height: 22px;
  top: 13px;
  right: 11px;
  position: absolute;
  display: inline-block;
}
.react-images__navigation .css-1h82jk3::after {
  content: "";
  width: 22px;
  background: url("/left.svg") no-repeat center/cover;
  height: 22px;
  top: 13px;
  left: 15px;
  position: absolute;
  display: inline-block;
}

.react-images__navigation button svg {
  display: none;
}
@media (max-width: 768px) {
  .react-images__navigation button {
    height: 30px;
    width: 30px;
  }

  .react-images__navigation button:first-child::after {
    width: 12px;
    height: 12px;
    top: 9px;
    left: 9px;
  }

  .react-images__navigation button:last-child::after {
    width: 12px;
    height: 12px;
    top: 9px;
    right: 8px;
  }
}
