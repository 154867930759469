.m149wg3d{display:none;}@media (max-width:1030px){.m149wg3d{display:block;}}
.dk4frjx{display:none;}@media (min-width:1031px){.dk4frjx{display:block;}}
.f1mmjw35{background:#000000;width:100%;color:#ffffff;padding-bottom:41px;}.f1mmjw35 *{font-family:"YakuHanJP","Zen Kaku Gothic New",sans-serif;}.f1mmjw35 .ant-btn-text{padding:0px;height:auto;color:#ffffff !important;}@media (max-width:1200px){.f1mmjw35 .ant-btn-text{padding:0px;}}@media (max-width:1030px){.f1mmjw35 .ant-btn-text{font-family:"Zen Kaku Gothic New";font-style:normal;font-weight:400;font-size:14px;line-height:150%;}}
.f11zfa1j{max-width:1100px;width:100%;margin:0 auto;padding-top:46px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:120px;}@media (max-width:1300px){.f11zfa1j{padding-left:24px;padding-right:24px;}}@media (max-width:955px){.f11zfa1j{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0px;padding-top:23px;}}
.h1u3g9g9{font-family:"Zen Kaku Gothic New";font-style:normal;font-weight:700;font-size:16px;line-height:150%;color:#ffffff;margin-bottom:10px;}
.lo5jmdp{margin-bottom:29px;}.lo5jmdp button:hover{opacity:0.5;}
.l1kodf79{margin-top:78px;}.l1kodf79 .logo-description{font-family:"Zen Kaku Gothic New";font-style:normal;font-weight:400;font-size:14px;line-height:200%;margin-top:22px;color:#ffffff;margin-bottom:0px;}.l1kodf79 .perkup{margin-top:53px;font-family:"Montserrat";}@media (max-width:1030px){.l1kodf79 .perkup{margin-top:27px;}}@media (max-width:1030px){.l1kodf79{padding:0px 24px;margin-top:38px;}}
.s1ir9qcj{margin-top:55px;}@media (max-width:768px){.s1ir9qcj{margin-top:26px;}}
.s1r0qsd7{font-family:"Zen Kaku Gothic New";font-style:normal;font-weight:700;font-size:16px;line-height:150%;color:#ffffff;margin-bottom:19px;}@media (max-width:1030px){.s1r0qsd7{margin-bottom:17px;}}
.a1aj7ow1{margin-top:26px;}
.l3e2rvv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;}.l3e2rvv a:hover{opacity:0.5;}
