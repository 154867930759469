.w1ub1m07{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;min-height:68px;padding:0px 20px 0px 60px;max-width:1440px;margin:auto;}@media (min-width:769px){.w1ub1m07 .menu-icon{display:none;}}.w1ub1m07 > a{display:contents;}@media (max-width:768px){.w1ub1m07{padding:0 20px;}}@media (min-width:1440px){.w1ub1m07{padding:0px 50px;}}
.l13usupw{position:-webkit-sticky;position:sticky;top:0px;z-index:998;}.l13usupw *{font-family:"YakuHanJP","Zen Kaku Gothic New",sans-serif;}.l13usupw .ant-drawer-header-title{margin:0px;}
.cx8fq95{background-color:#FFFFFF;}
.l1f6wl7n{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:27px;}
.s5k7rq8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:21px;}.s5k7rq8 a{border-radius:8px;}.s5k7rq8 a:hover{opacity:0.8;}.s5k7rq8 .insta{color:black;font-size:24px;-webkit-transition:color 0.4s;transition:color 0.4s;}.s5k7rq8 .insta:hover{color:#F95F00;-webkit-transition:color 0.4s;transition:color 0.4s;}.s5k7rq8 .insta svg{border-radius:8px;}
.r7he0ke{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:25px;}@media (max-width:768px){.r7he0ke{display:none;}}
.lhl9gwd{margin-bottom:70px;}
.c14wjmi3{padding:21px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}
.i67b0dc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;height:85%;margin-top:7.38%;text-align:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.mbdoyvd{margin-bottom:38px;text-align:center;}
.s1d494ib{margin-top:8.63%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:25px;margin-bottom:15.51%;}.s1d494ib .insta{color:black;font-size:22px;height:22px;width:22px;-webkit-transition:color 0.4s;transition:color 0.4s;}.s1d494ib .insta:hover{color:#F95F00;-webkit-transition:color 0.4s;transition:color 0.4s;}.s1d494ib svg{border-radius:8px;}
.lriid5b{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.t1alfix{font-style:normal;font-weight:400;font-size:12px;line-height:200%;color:#1b1b1b;margin-top:15px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.t1alfix .privacy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.pf2n6lf{font-size:12px;line-height:200%;color:#1b1b1b;padding-left:10px;}.pf2n6lf:hover{color:#1b1b1b;}
.ctos0xa{margin-top:3.7%;font-family:"Montserrat";font-style:normal;font-weight:400;font-size:10px;line-height:200%;color:#1b1b1b;}
